import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
// import React,{ useEffect, useState } from "react";
// import * as Styles from "../style/home.module.scss"
import mail from "../assets/images/Mail.png";
import mobile from "../assets/images/Mobile.png";
import Twitter from "../assets/images/Twitter.png";
import Location from "../assets/images/Location.png";
import { StaticImage } from "gatsby-plugin-image"
import NavbarComponent from "../components/Navbar"
import Contactus from "../components/Contactus"
import Layout from "../components/layout"
import Carousel from 'react-elastic-carousel';
import Seo from "../components/seo"
import { Icon } from 'react-icons-kit'
import { check } from 'react-icons-kit/fa/check'
import Header from "../components/header_v1"
import { caretRight } from 'react-icons-kit/fa/caretRight'
import { facebookOfficial } from 'react-icons-kit/fa/facebookOfficial'
import { linkedinSquare } from 'react-icons-kit/fa/linkedinSquare'
import { youtubePlay } from 'react-icons-kit/fa/youtubePlay'
import { twitter } from 'react-icons-kit/fa/twitter'
import { Row, Container, Col, Tab, Nav, Button, Form } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as Styles from "../style/home.module.scss";
import * as Style from "../style/about.module.scss";
//Components Import
import CallToAction from "../components/button/index";
import CustomAccordian from "../components/accordian/index";
//Images and Svg Import
import logoSVG from "../assets/images/Logo.png";

import bizLiblogoPNG from "../assets/images/BizLab Logo.png";
import joSimlogoPNG from "../assets/images/Josim Logo.png";
import emailSvg from "../assets/images/email.svg";
import phoneSvg from "../assets/images/phone.svg";
import twitterSvg from "../assets/images/Twitter.svg";
import why1 from "../assets/images/why1.svg";
import why2 from "../assets/images/why2.svg";
import why3 from "../assets/images/why3.svg";
import why4 from "../assets/images/why4.svg";
import why5 from "../assets/images/why5.svg";
import why6 from "../assets/images/why6.svg";
import abstract1 from "../assets/images/Repeat Grid 3.svg";
// import abstract1 from "../assets/images/abstract1.svg";
import quote from "../assets/images/quotes.png";
import abstract2 from "../assets/images/abstract2.svg";
import abstract3 from "../assets/images/abstract3.png";
import abstract4 from "../assets/images/abstract4.png";
import angle1 from "../assets/images/angle1.svg";
import testimon from "../assets/images/testimon.png";
import Testimational2 from "../assets/images/Testimonal2.png";
import Testimational3 from "../assets/images/Testimonal3.png";
import Testimational4 from "../assets/images/Testimonal4.png";
import Testimational5 from "../assets/images/Testimonal5.png";
import angle2 from "../assets/images/angle2.svg";
import dashboardSvgAbstract from "../assets/images/Dashboard-section-background.svg";
import about1 from "../assets/images/about1.png"
import about2 from "../assets/images/about2.png"
import about3 from "../assets/images/about3.png"
import nutshell from "../assets/images/Skill_Elevator_in_nutshell.png"
import chontactAbstract from "../assets/images/contact-abstract.png"
import chontactAbstractMobile from "../assets/images/contact-abstract2.png"
import challenge1 from "../assets/images/challenge1.png"
import challenge2 from "../assets/images/challenge2.png"
import challenge3 from "../assets/images/challenge3.png"
import challenge4 from "../assets/images/challenge4.png"
import challenge5 from "../assets/images/challenge5.png"
import challenge6 from "../assets/images/challenge6.png"
import Picture1 from "../assets/images/Picture1.png"
import client1 from "../assets/images/Skill India.png"
import client2 from "../assets/images/Startup India.png"
import client3 from "../assets/images/NSDC.png"
import client4 from "../assets/images/Digital_India.png"
import Startup from "../assets/images/Startup.png"
import mock from "../assets/images/Mock.png"
import mock1 from "../assets/images/Mock1.png"
import mock3 from "../assets/images/Mock 3.png"
import dasboardImage from "../assets/images/Dashboard.png"
import No_Standard_Learning_Source from "../assets/images/No_Standard_Learning_Source.png"
import No_Practical_Training from "../assets/images/No_Practical_Training.png"
import Freshers_are_Overlooked from "../assets/images/Freshers_are_Overlooked.png"
import Not_Well_Equiped_College from "../assets/images/Not_Well_Equiped_College.png"
import Steep_Learning_Curve from "../assets/images/Steep_Learning_Curve.png"
import Time_Cost_Factor from "../assets/images/Time_Cost_Factor.png"
import testimonial1 from "../assets/images/testimonial1.png"
import challengesBackground from "../assets/images/challenges-background.png"
import Increase_your_experience from "../assets/images/Increase_your_experience.png";
import Quicker_Job_Placements from "../assets/images/Quicker_Job_Placements.png";
import Online_Video_tutorials from "../assets/images/Online_Video_tutorials.png";
import Gain_confidence from "../assets/images/Gain_confidence.png";
import All_Industries_in_one_place from "../assets/images/All_Industries_in_one_place.png";
import Easy_to_use_web_app from "../assets/images/Easy_to_use_web_app.png";

const IndexPage = () => {
  const about = [
    {
      imgUrl: about1,
      heading: "India's first finance learning platform",
      details: "India's first web platform to provide complete Industry and Job experience in a virtual environment in the accounting and finance field"
    },
    {
      imgUrl: about2,
      heading: "Module based Learning",
      details: "All Accounting, tax, statutory filing and other business works have been created as modules with bills, transactions, procedural documents"
    },
    {
      imgUrl: about3,
      heading: "Actual Job Experience",
      details: "Skill elevator modules are not theory programs but is actual job works that are created in virtual companies where one has to work to gain"
    }
  ]
  const challenge = [
    {
      imgUrl: challenge1,
      heading: "No Practical Training",
      details: "Students do not have exposure to practical training in the colleges"
    },
    {
      imgUrl: challenge2,
      heading: "Colleges are not Well Equiped",
      details: "Colleges are not able to design a practical working lab for commerce and business students"
    },
    {
      imgUrl: challenge3,
      heading: "Freshers are Overlooked",
      details: "Most of the companies are looking for experienced commerce students and thereby freshers are overlooked"
    },
    {
      imgUrl: challenge4,
      heading: "Time & Cost Factor",
      details: "For Corporates & CA firms, providing hands-on job training taking more time and cost"
    },
    {
      imgUrl: challenge5,
      heading: "Steep Learning Curve",
      details: "Fast track in career has become challenging without fast track training"
    },
    {
      imgUrl: challenge6,
      heading: "No Standard Learning Source",
      details: "There is no product in the world which can simulate the actual industry works for students and corporates"
    }
  ]
  const why = [
    {
      imgUrl: Easy_to_use_web_app,
      heading: "Easy to use web app",
      details: "Practical Training Software for Accounts and Tax"
    },
    {
      imgUrl: Increase_your_experience,
      heading: "Increase your experience",
      details: "Virtual/ Simulated Work Experience in Commerce"
    },
    {
      imgUrl: Gain_confidence,
      heading: "Gain confidence",
      details: "Virtual/ Simulated Work Experience in Commerce"
    },
    {
      imgUrl: Quicker_Job_Placements,
      heading: "Quicker Job Placements",
      details: "A Digital Platform for Skill Education and Virtual Entity to get work experience"
    },
    {
      imgUrl: All_Industries_in_one_place,
      heading: "Various Industries in one place",
      details: "India's No.1 WebApplication to Simulate Industry works in Virtual envirnoment"
    },
    {
      imgUrl: Online_Video_tutorials,
      heading: "Online help & Video tutorials",
      details: "Great online working modules offering rich & hands-on job experience quickly"
    }
  ]
  const breakpoints = [
    { width: 500, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 992, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 }
  ]
  return (
    <Layout>
      <Seo title="Home" />
      <div id="Home" className={Styles.banner}>
        <Header />
        <hr style={{ border: "1px solid #FFFFFF", height: "0.005em", color: "#ffffff", backgroundColor: "#ffffff" }} />
        <Row className={Styles.height80}>
          <Col md={6} className={Styles.bannerText}>
            <div>
              <h1 className={`${Styles.fontWhite} ${Styles.bannerHeadingLeft} ${Styles.bold}`}>
                India's first online platform <br />
                to provide job experience
                <br />
                digitally
                {/* <Link to="/about-us/">Go to page 2</Link> <br /> */}
              </h1>
              <p className={`${Styles.fontWhites} ${Styles.paddingTop10}`}>The best platform for commerce skill & <br />job enhancement. Let's get ready for the job!.</p>
              <Button className={Styles.callToAction2}>GET STARTED</Button>
            </div>
          </Col>
        </Row>
      </div>
      <div >
        <Container>
          <Row>
            <Col md={12} className={Styles.startup} >
            </Col>
          </Row>
        </Container>
      </div>
      <div >
        <Container fluid >
          <Row className={Styles.heightFull} style={{ marginTop: "-10px" }}>
            <Col md={2}></Col>
            <Col md={4} >
              <div className={`${Styles.TextLeftBold} ${Styles.paddingTop100}`}>
                Welcome to Skill-Elevator
              </div>
              <div className={`${Styles.TextJustifyBoldSmall}`}>
                A digital platform for skill and employment enhancement
              </div>
              <div className={`${Styles.TextJustify} ${Styles.lineHeight30} ${Styles.paddingTop20}`} >
                Skill Elevator is India's first web platform to provide complete Industry and Job experience in virtual environment in accounting and finance filed. <br />
                Under this platform, all required accounting, tax, statutory filing and other business works have been created as a practical modules
                with actual bills, transactions, filings, procedural documents etc which are going to be used in while working in our modules.
              </div>
            </Col>
            <Col md={5} className={Styles.welcome}></Col>
            <Col md={1}></Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container fluid  className={Styles.digitalplatform} style={{ background: "linear-gradient(77.21deg, #153361 2.74%, #0E4292 100%)" }}>
          <Row>
            <Col md={12} >
              <div className={`${Styles.TextCenterBoldWhite} ${Styles.paddingTop100}`}  >
                Skill Elevator Platforms
              </div>
              <p className={`${Styles.TextCenterBoldWhiteSmall}`}  >A digital platform for skill and employment enhancement</p>
            </Col>
          </Row>
          <Row style={{ paddingTop: "30px", paddingBottom: "30px" }}>
            <Col md={2}></Col>
            <Col md={4}>
              <div className={` ${Styles.card_left_bg}`}  >
                <div style={{ paddingTop: "5%", paddingBottom: "5%" }}>
                  <p className={`${Styles.TextCenterBoldBlackSmall}`}  >Business Laboratory</p>
                  <p className={`${Styles.TextCenterBlackSmall}`}  >A digital laboratory to enhance <br /> accounting skills</p>
                  <div style={{ width: "150px", alignContent: "center", margin: "0 auto" }}>
                    {/* <CallToAction title="BIZLAB" size="xl" /> */}
                    <Button className={Styles.callToActionMdOutlined_v1}>BIZLAB</Button>
                  </div></div>
              </div>
            </Col>
            <Col md={4}>
              <div className={` ${Styles.card_right_bg}`} >
                <div style={{ paddingTop: "5%", paddingBottom: "5%" }}>
                  <p className={`${Styles.TextCenterBoldBlackSmall}`}  >Job Simulator</p>
                  <p className={`${Styles.TextCenterBlackSmall}`}  >An online platform to enhance accounting<br /> employment</p>
                  <div style={{ width: "150px", alignContent: "center", margin: "0 auto" }}>
                    <Button className={Styles.callToActionMdOutlined_v1}>JOSIM</Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </div>
      <div >
        <Container fluid>
          <Row className={` ${Styles.heightFull} ${Styles.paddingBottom100}`}   >
            <Col md={6} className={Styles.BizLab}></Col>
            <Col md={1} ></Col>
            <Col md={5} className={` ${Styles.paddingTop90} `}>
              <div className={`${Styles.TextLeftBold_v1} `}>
                Business Laboratory
              </div>
              <div className={Styles.TextJustify}>
                Virtual Experience of various Accounting and Tax works
              </div>
              <div>
                <p className="row mt-3">
                  <Col xs={1} md={1}>
                    <Icon icon={check} className={Styles.checkicon} size={9} />
                  </Col>
                  <Col xs={11} md={11}>
                    <span className={Styles.fontBlueDark}>20 in-depth working modules</span>
                  </Col>
                </p>
                <p className="row mt-3">
                  <Col xs={1} md={1}>
                    <Icon icon={check} className={Styles.checkicon} size={9} />
                  </Col>
                  <Col xs={11} md={11}>
                    <span className={Styles.fontBlueDark}>Get access to one Virtual company to work</span>
                  </Col>
                </p>
                <p className="row mt-3">
                  <Col xs={1} md={1}>
                    <Icon icon={check} className={Styles.checkicon} size={9} />
                  </Col>
                  <Col xs={11} md={11}>
                    <span className={Styles.fontBlueDark}>2 years hands-on experience certificate <br /> with Pro Accountant Qualification</span>
                  </Col>
                </p>
                <p className="row mt-3">
                  <span> <Button className={Styles.callToActionMd}>Signup for free</Button></span>
                  <span style={{ paddingLeft: "10px" }}> <Button className={Styles.callToActionMdOutlined}>Check Demo</Button></span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <hr style={{ border: "#fff", height: "0.005em", color: "#C4C4C4", backgroundColor: "#C4C4C4" }} className={Styles.hrline} />
      </div>
      <div >
        <Container fluid>
          <Row className={` ${Styles.heightFull} ${Styles.paddingBottom100}`} className={Styles.jobsimulator} style={{ marginTop: "-36px" }}>
            <Col md={1} ></Col>
            <Col md={5} >
              <div className={`${Styles.TextLeftBold_v1} ${Styles.paddingTop100}`}>
                Job Simulator
              </div>
              <div className={Styles.TextJustify}>
                Simulated Experience of various Finance <br />& Accounting Job roles in Industry
              </div>
              <div>
                <p className="row mt-3">
                  <Col xs={1} md={1}>
                    <Icon icon={check} className={Styles.checkicon} size={9} />
                  </Col>
                  <Col xs={11} md={11}>
                    <span className={Styles.fontBlueDark}>5 On job Roles/positions</span>
                  </Col>
                </p>
                <p className="row mt-3">
                  <Col xs={1} md={1}>
                    <Icon icon={check} className={Styles.checkicon} size={9} />
                  </Col>
                  <Col xs={11} md={11}>
                    <span className={Styles.fontBlueDark}>Get access to one Virtual company to work</span>
                  </Col>
                </p>
                <p className="row mt-3">
                  <Col xs={1} md={1}>
                    <Icon icon={check} className={Styles.checkicon} size={9} />
                  </Col>
                  <Col xs={11} md={11}>
                    <span className={Styles.fontBlueDark}>Get 3 years job training experience certificate with <br />
                      Pro FinanceManager Qualification</span>
                  </Col>
                </p>
                <p className="row mt-3">

                  <span> <Button className={Styles.callToActionMd}>Signup for free</Button></span>
                  <span style={{ paddingLeft: "10px" }}> <Button className={Styles.callToActionMdOutlined}>Check Demo</Button></span>

                </p>
              </div>
            </Col>

            <Col md={6} className={Styles.JoSIM}></Col>
          </Row>
        </Container>
      </div>
      <div id="Bizlab" className={Styles.bizLabSection}>
        <Container className={Styles.heightFull}>
          <Row>
            <Col md={12} >
              <div className={`${Styles.TextCenterBoldWhite} ${Styles.paddingTop60}`}  >
                Challenges faced by <br />
                Commerce Students, Colleges And Industry
              </div>
            </Col>
          </Row>
          <Row style={{ paddingTop: "30px" }}>
            <Col md={1}></Col>
            <Col md={2} className="d-flex align-items-center ">
              <img src={No_Practical_Training} className={Styles.roundImage} />
            </Col>
            <Col md={3} >
              <div className={Styles.TextJustifyWhite} style={{ paddingTop: "30px" }}>
                No Practical Training
              </div>
              <div className={Styles.TextJustifyWhiteSmall} style={{ paddingTop: "10px" }} >
                Students do not have exposure to  practical training in the colleges
              </div>
            </Col>
            <Col md={2} className="d-flex align-items-center ">
              <img src={No_Standard_Learning_Source} className={Styles.roundImage} />
            </Col>
            <Col md={3} >
              <div className={Styles.TextJustifyWhite} style={{ paddingTop: "30px" }}>
                Time & Cost Factor
              </div>
              <div className={Styles.TextJustifyWhiteSmall} style={{ paddingTop: "4px" }} >
                For Corporates & CA firms, providing hands-on job training taking more time and cost
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
          <Row style={{ paddingTop: "30px" }}>
            <Col md={1}></Col>
            <Col md={2} className="d-flex align-items-center ">
              <img src={Not_Well_Equiped_College} className={Styles.roundImage} />
            </Col>
            <Col md={3} >
              <div className={Styles.TextJustifyWhite} style={{ paddingTop: "30px" }}>
                Not Well Equiped College
              </div>
              <div className={Styles.TextJustifyWhiteSmall} style={{ paddingTop: "4px" }} >
                Colleges are not able to design a practical working lab for commerce and business students
              </div>
            </Col>
            <Col md={2} className="d-flex align-items-center ">
              <img src={Steep_Learning_Curve} className={Styles.roundImage} />
            </Col>
            <Col md={3} >
              <div className={Styles.TextJustifyWhite} style={{ paddingTop: "30px" }}>
                Steep Learning Curve
              </div>
              <div className={Styles.TextJustifyWhiteSmall} style={{ paddingTop: "4px" }} >
                Fast track in career has become challenging without fast track training
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
          <Row style={{ paddingTop: "30px" }}>
            <Col md={1}></Col>
            <Col md={2} className="d-flex align-items-center ">
              <img src={Freshers_are_Overlooked} className={Styles.roundImage} />
            </Col>
            <Col md={3} >
              <div className={Styles.TextJustifyWhite} style={{ paddingTop: "30px" }}>
                Freshers are Overlooked
              </div>
              <div className={Styles.TextJustifyWhiteSmall} style={{ paddingTop: "4px" }} >
                Most of the companies are looking for experienced commerce students and thereby freshers are overlooked
              </div>
            </Col>
            <Col md={2} className="d-flex align-items-center ">
              <img src={Time_Cost_Factor} className={Styles.roundImage} />
            </Col>
            <Col md={3} >
              <div className={Styles.TextJustifyWhite} style={{ paddingTop: "30px" }}>
                No Standard Learning Source
              </div>
              <div className={Styles.TextJustifyWhiteSmall} style={{ paddingTop: "4px" }} >
                There is no product in the world which can simulate the actual industry works for students and corporates
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
          <Row style={{ paddingBottom: "30px" }}>
            <Col md={12}></Col>
          </Row>
        </Container>
      </div>
      <div id="Why Skill Elevator" className={Styles.whySkillElevatorSection}>
        <img src={angle2} className={`${Styles.angle2} d-block d-sm-none`} />
        <Container className={Styles.whyskill}>
          <Row>
            <Col md={4}></Col>
            <Col md={4}>
              <div className={`${Styles.TextLeftBold_v1} ${Styles.paddingTop30} ${Styles.paddingBottom30}`}>
                Why Skill Elevator
              </div>
            </Col>
          </Row>
          <Col md={4}></Col>
          {/* <h2 className={Styles.aboutHeading} style={{ marginBottom: 40 }}>Why Skill Elevator?</h2> */}
          <Row>
            {why.map((whyItem) => (
              <Col md={4} className="mb-3">
                <div className={Styles.whyCards}>
                  <img src={whyItem.imgUrl} className="mb-3" alt="why" style={{ width: "56px", height: "56px" }} />
                  <div>
                    <p className={Styles.aboutTitle_v1} style={{ marginBottom: 5 }}>{whyItem.heading}</p>
                    <p className={Styles.challengeDetails_v1}>{whyItem.details}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div>
        <div className={`${Styles.nutshellSection} `}>
          <Container className={Styles.heightFull} className={Styles.nutshel}>
            <Row>
              <Col md={6} >
                <div className={`${Styles.TextLeftBoldWhite} ${Styles.paddingTop60} `}>
                  <span style={{ borderBottom: "1px solid #ffff" }} className={Styles.SkillElevatorinanutshell}>Skill Elevator in a nutshell</span>
                </div>
                <div className={Styles.TextJustifyWhite}>
                  Simulated Experience of various Finance <br />& Accounting Job roles in Industry
                </div>
                <div>
                  <p className="row mt-3">
                    <Col xs={1} md={1}>
                      <Icon icon={check} className={Styles.checkicon} size={9} />
                    </Col>
                    <Col xs={11} md={11}>
                      <span className={Styles.fontWhite}>Practical Training Software for Accounts and Tax</span>
                    </Col>
                  </p>
                  <p className="row mt-3">
                    <Col xs={1} md={1}>
                      <Icon icon={check} className={Styles.checkicon} size={9} />
                    </Col>
                    <Col xs={11} md={11}>
                      <span className={Styles.fontWhite}>Employable enhancement for commerce student</span>
                    </Col>
                  </p>
                  <p className="row mt-3">
                    <Col xs={1} md={1}>
                      <Icon icon={check} className={Styles.checkicon} size={9} />
                    </Col>
                    <Col xs={11} md={11}>
                      <span className={Styles.fontWhite}>Online/Simulated job experience</span>
                    </Col>
                  </p>
                </div>
              </Col>
              <Col md={6} >
                <div>
                  <img src={nutshell} className={Styles.nutshellSectionImage} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className={Styles.contactus_bg}>
        <Container style={{ paddingTop: "60px" }}>
          <Contactus></Contactus>
        </Container>
      </div>
      {/* <div id="testimonials" className={Styles.whySkillElevatorSection}>
        <img src={angle2} className={`${Styles.angle2} d-block d-sm-none`} />
        <Container>
          <h2 className={Styles.aboutHeading} style={{ marginBottom: 40 }}>Testimonials</h2>
          <Carousel breakPoints={breakpoints} autoplay={true}>
            <div className="our-team ">
              <div>
                <div><img src={testimon} alt="testimon" className={Styles.testing} /></div>
                <p className={Styles.content}>Good college with qualified staffs.teacher in coaching</p>
                <p className={Styles.content}> very much no ragging. discipline mainteined in friendly way.</p>
                <p className={Styles.content}> cse lap facilities and staffs are so very good.happy</p>
                <p className={Styles.content}> to be at a good job position now.good college to join.</p>
                <h5 className={Styles.John}>John peter</h5>
              </div>
            </div>
            <div className="our-team " >
              <div>
                <div><img src={Testimational2} alt="testimon" className={Styles.testing} /></div>
                <p className={Styles.content}>Good college with qualified staffs.teacher in coaching</p>
                <p className={Styles.content}> very much no ragging. discipline mainteined in friendly way.</p>
                <p className={Styles.content}> cse lap facilities and staffs are so very good.happy</p>
                <p className={Styles.content}> to be at a good job position now.good college to join.</p>
                <h5 className={Styles.John}>John peter</h5>
              </div>
            </div>
            <div className="our-team ">
              <div>
                <div><img src={Testimational3} alt="testimon" className={Styles.testing} /></div>
                <p className={Styles.content}>Good college with qualified staffs.teacher in coaching</p>
                <p className={Styles.content}> very much no ragging. discipline mainteined in friendly way.</p>
                <p className={Styles.content}> cse lap facilities and staffs are so very good.happy</p>
                <p className={Styles.content}> to be at a good job position now.good college to join.</p>
                <h5 className={Styles.John}>John peter</h5>
              </div>
            </div>
            <div className="our-team ">
              <div>
                <div><img src={Testimational4} alt="testimon" className={Styles.testing} /></div>
                <p className={Styles.content}>Good college with qualified staffs.teacher in coaching</p>
                <p className={Styles.content}> very much no ragging. discipline mainteined in friendly way.</p>
                <p className={Styles.content}> cse lap facilities and staffs are so very good.happy</p>
                <p className={Styles.content}> to be at a good job position now.good college to join.</p>
                <h5 className={Styles.John}>John peter</h5>
              </div>
            </div>
            <div className="our-team ">
              <div>
                <div><img src={Testimational5} alt="testimon" className={Styles.testing} /></div>
                <p className={Styles.content}>Good college with qualified staffs.teacher in coaching</p>
                <p className={Styles.content}> very much no ragging. discipline mainteined in friendly way.</p>
                <p className={Styles.content}> cse lap facilities and staffs are so very good.happy</p>
                <p className={Styles.content}> to be at a good job position now.good college to join.</p>
                <h5 className={Styles.John}>John peter</h5>
              </div>
            </div>
            <div className="our-team ">
              <div>
                <div><img src={testimon} alt="testimon" className={Styles.testing} /></div>
                <p className={Styles.content}>Good college with qualified staffs.teacher in coaching</p>
                <p className={Styles.content}> very much no ragging. discipline mainteined in friendly way.</p>
                <p className={Styles.content}> cse lap facilities and staffs are so very good.happy</p>
                <p className={Styles.content}> to be at a good job position now.good college to join.</p>
                <h5 className={Styles.John}>John peter</h5>
              </div>
            </div>
            <div className="our-team ">
              <div>
                <div><img src={testimon} alt="testimon" className={Styles.testing} /></div>
                <p className={Styles.content}>Good college with qualified staffs.teacher in coaching</p>
                <p className={Styles.content}> very much no ragging. discipline mainteined in friendly way.</p>
                <p className={Styles.content}> cse lap facilities and staffs are so very good.happy</p>
                <p className={Styles.content}> to be at a good job position now.good college to join.</p>
                <h5 className={Styles.John}>John peter</h5>
              </div>
            </div>
          </Carousel>
        </Container>
      </div> */}
    </Layout >
  )
}
export default IndexPage
